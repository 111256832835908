import { AnyAction } from "redux";
import {
  INCREMENT,
  UPDATE_TOKEN,
  UPDATE_IS_LOADING,
  UPDATE_CURRENTTAB,
  UPDATE_BREADCRUMB,
  SEARCH_CLICK,
  UPDATE_USER_ROLE,
  UPDATE_USER_INFORMATION,
} from "../action";
import ReduxStateProps from "../Redux.props";

const initialState: ReduxStateProps = {
  count: 0,
  token: "No token",
  isLoading: false,
  userRole: "",
  currentTab: "Home",
  crumbs: [{ path: "/dashboard", name: "Dashboard" }],
  searchClick: "contractSearchClicked",
  user: {
    id: 0,
    firstName: "",
    lastName: "",
    userName: "",
    loginPassword: "",
    roleTypeId: 0,
    address: "",
    phone: "",
    fax: "",
    emailId: "",
    companyName: "",
    employeeId: "",
    pearsonEmployee: "",
    userStatus: "",
    inHouseContact: "",
    businessUnit: "",
    comments: "",
    countryId: 0,
    languageId: 0,
    departmentName: "",
    roleName: 0,
    languageName: "",
    totalCount: 0,
  },
};

export default function Reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case INCREMENT:
      return { ...state, count: state.count + 1 };
    case UPDATE_TOKEN:
      return { ...state, token: action.payload };
    case UPDATE_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case UPDATE_CURRENTTAB:
      return { ...state, currentTab: action.payload };
    case UPDATE_BREADCRUMB:
      return { ...state, crumbs: action.payload };
    case SEARCH_CLICK:
      return { ...state, searchClick: action.payload };
    case UPDATE_USER_ROLE:
      return { ...state, userRole: action.payload };
    case UPDATE_USER_INFORMATION:
      return { ...state, user: action.payload };
    default:
      return state;
  }
}
